import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, useTheme } from '@material-ui/core';
import { images } from '@utilitywarehouse/partner-ui-assets';
import {
  ElectricityIcon,
  FlagPointIcon,
  InsuranceIcon,
  MobileIcon,
  WifiIcon,
} from '@utilitywarehouse/partner-ui-icons';

import {
  useTracking,
  useTrackOnce,
} from '@utilitywarehouse/partner-tracking-react';
import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import { EVENTS } from 'app/lib/analytics/constants';
import useOptlyEvent from 'app/lib/analytics/Optimizely/useOptlyEvent';
import { events as optlyEvents } from 'app/lib/analytics/Optimizely/features';
import { CBC_TERMS_URL, GENERAL_TERMS_URL } from 'app/config/externalLinks';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'modules/Shared/PageHeading';
import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import SectionCentered from 'modules/layout/SectionCentered';
import NewCBCChallengeDialog from 'modules/NewCashback/Challenge/Dialog';
import ContentDropdown from 'components/modules/Shared/ContentDropdown';
import AddressPicker from './AddressPicker';
import useStyles from './styles';
import AddressExistsDialog from 'modules/EntryOrganic/AddressExistsDialog';
import Link from 'modules/Shared/Link';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';
import { useHandleCTAConversionParam } from 'app/hooks/useCTAConversionParam';

const EntryOrganic = ({
  getQuote,
  getQuoteLoading,
  isPropertyOwner,
  setIsPropertyOwner,
  getAddressList,
  addressPicker,
  setAddressSelection,
  setManualEntry,
  setManualEntryField,
  addressFields,
  addressExistsDialogOpen,
  onAddressExistsDialogContinue,
  ...props
}) => {
  const { isCTAConversionJourney } = useHandleCTAConversionParam();
  const classes = useStyles();
  const theme = useTheme();
  const [legalDropdown, setLegalDropdown] = useState({
    services: false,
    cashback: false,
  });
  const trackOnce = useTrackOnce();
  const tracking = useTracking();
  const applicationStartBody = useEvents(EVENTS.APPLICATION_STARTED);
  const optlyEvent = useOptlyEvent();

  const newVariantFlagEnabled = useSelector((state) =>
    FeatureFlagsApi.getFlag(state, FLAGS.ORGANIC_PAGE_NEW_VARIANT)
  );

  const { variant: organicPageNewVariant } = useFeature(
    features.ORGANIC_PAGE_NEW_VARIANT
  );

  const newVariantEnabled =
    newVariantFlagEnabled ||
    organicPageNewVariant === variations[features.ORGANIC_PAGE_NEW_VARIANT].ON;

  const baseStyles = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  };

  useEffect(() => {
    trackOnce(EVENTS.APPLICATION_STARTED, applicationStartBody);
  }, [trackOnce, applicationStartBody]);

  useEffect(() => {
    optlyEvent(
      isCTAConversionJourney
        ? optlyEvents.ENGAGED_APPLICATION_START
        : optlyEvents.APPLICATION_START,
      {}
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddressConfirm = React.useCallback(
    (address) => {
      getQuote(address, isPropertyOwner);
      tracking.track(EVENTS.ADDRESS_SUBMITTED, {
        manual_address_entered: address.addressNotListed ?? false,
        is_homeowner: isPropertyOwner,
      });
      optlyEvent(optlyEvents.ADDRESS_SUBMITTED, {});
    },
    [getQuote, isPropertyOwner, tracking, optlyEvent]
  );

  const location = useLocation();
  const showCBCChallengeDialog = location?.hash === '#cbc';

  return (
    <PageTemplate
      baseStyles={baseStyles}
      headerProps={props}
      footerProps={props}
    >
      <PageHeading theme="light" />
      <ApplicationCheck withoutServices>
        <AddressExistsDialog
          open={addressExistsDialogOpen}
          onContinue={onAddressExistsDialogContinue}
        />
        <SectionCentered
          component="section"
          newLayout
          classes={{
            root: classes.sectionGray,
            container: classes.topSection,
          }}
        >
          <div className={clsx({ [classes.hero]: newVariantEnabled })}>
            <div className={clsx({ [classes.wrapper]: newVariantEnabled })}>
              <header>
                <Typography
                  id="organic-title"
                  variant="h3"
                  component="h2"
                  className={classes.headerTitle}
                >
                  Save time, save money, save a bundle
                </Typography>
              </header>
              {newVariantEnabled && (
                <Typography id="organic-subtitle" className={classes.subTitle}>
                  It`s easy to save on your household bills when you bundle your
                  energy, broadband and mobile
                </Typography>
              )}
              <AddressPicker
                isPropertyOwner={isPropertyOwner}
                setIsPropertyOwner={setIsPropertyOwner}
                onConfirm={handleAddressConfirm}
                loading={getQuoteLoading}
                addressPicker={addressPicker}
                getAddressList={getAddressList}
                setManualEntry={setManualEntry}
                setManualEntryField={setManualEntryField}
                addressFields={addressFields}
                onSelectAddress={setAddressSelection}
              />
            </div>
            {newVariantEnabled && (
              <picture className={classes.img}>
                <img src="images/landing/services.png" alt="" />
              </picture>
            )}
          </div>
        </SectionCentered>

        {!newVariantEnabled && (
          <>
            <SectionCentered
              newLayout
              component="section"
              classes={{
                root: classes.sectionWhite,
                container: classes.section,
              }}
            >
              <header className={classes.sectionHeading}>
                <Typography
                  variant="h3"
                  component="h2"
                  color="primary"
                  className={classes.sectionTitle}
                >
                  Guaranteed savings on top of our market-leading services
                </Typography>
              </header>
              <div className={classes.servicesWrapper}>
                <article className={classes.serviceItem}>
                  <header>
                    <img
                      className={classes.serviceIconLarge}
                      src={images.icons.productIcons.Electricity}
                      alt=""
                    />
                    <ElectricityIcon
                      className={clsx(
                        classes.serviceIconSmall,
                        classes.serviceIconElectricity
                      )}
                    />
                    <Typography
                      variant="h4"
                      component="h3"
                      className={classes.subSectionTitle}
                    >
                      Energy
                    </Typography>
                  </header>
                  <Typography>Always less than the Price Cap</Typography>
                </article>
                <article className={classes.serviceItem}>
                  <header>
                    <img
                      className={classes.serviceIconLarge}
                      src={images.icons.productIcons.Broadband}
                      alt=""
                    />
                    <WifiIcon
                      className={clsx(
                        classes.serviceIconSmall,
                        classes.serviceIconBroadband
                      )}
                    />
                    <Typography
                      variant="h4"
                      component="h3"
                      className={classes.subSectionTitle}
                    >
                      Broadband
                    </Typography>
                  </header>
                  <Typography>
                    UK’s best value superfast broadband at £21.60 a month with
                    no price hikes
                  </Typography>
                </article>
                <article className={classes.serviceItem}>
                  <header>
                    <img
                      className={classes.serviceIconLarge}
                      src={images.icons.productIcons.Mobile}
                      alt=""
                    />
                    <MobileIcon
                      className={clsx(
                        classes.serviceIconSmall,
                        classes.serviceIconMobile
                      )}
                    />
                    <Typography
                      variant="h4"
                      component="h3"
                      className={classes.subSectionTitle}
                    >
                      Mobile
                    </Typography>
                  </header>
                  <Typography>
                    UK’s best value Unlimited SIM deal when you take three or
                    more
                  </Typography>
                </article>
                <article className={classes.serviceItem}>
                  <header>
                    <img
                      className={classes.serviceIconLarge}
                      src={images.icons.productIcons.Insurance}
                      alt=""
                    />
                    <InsuranceIcon
                      className={clsx(
                        classes.serviceIconSmall,
                        classes.serviceIconInsurance
                      )}
                    />
                    <Typography
                      variant="h4"
                      component="h3"
                      className={classes.subSectionTitle}
                    >
                      Insurance
                    </Typography>
                  </header>
                  <Typography>
                    Great value 5-star rated Boiler & Home Cover to help you
                    save even more
                  </Typography>
                </article>
              </div>
              <ContentDropdown
                label="The legal bit"
                open={legalDropdown.services}
                onOpen={() =>
                  setLegalDropdown({
                    ...legalDropdown,
                    services: !legalDropdown.services,
                  })
                }
                classes={{ root: classes.servicesLegalDropdown }}
              >
                <Typography component="aside">
                  <Typography component="h3" variant="h4">
                    Energy
                  </Typography>
                  <p>
                    * £50 discount applies to customers who switch their energy
                    plus 2 or 3 other services (broadband, mobile and/or
                    insurance&mdash;Bill Protector excluded. Boiler & Home Cover
                    also excluded for tenants). Lower discounts will be applied
                    to customers taking just energy, or energy plus one other
                    service. Discounts only apply to customers on a variable
                    energy tariff paying by Direct Debit with a credit meter and
                    are calculated at a flat rate using the typical domestic
                    consumption (as set by Ofgem) compared to the Government’s
                    Energy Price Guarantee level for default energy tariffs. %
                    discount could be higher or lower depending on actual
                    consumption. See terms for more details.
                  </p>
                  <Typography component="h3" variant="h4">
                    Mobile
                  </Typography>
                  <p>
                    The UK&rsquo;s best Unlimited Multi-SIM deal in the UK:
                    Based on price, speed and reliability. Price: UK’s cheapest
                    Unlimited Multi-SIM package in the UK on a 30 day rolling
                    contract (excluding introductory and promotional offers).
                    Prices correct as of 21.02.2023. Speed & reliability: We use
                    the EE network. EE was the winner in the overall (best)
                    network performance category for both RootMetrics’ H2 2021
                    testing and for the past eight years in a row. Experiences &
                    coverage may vary. Rootmetrics award is not an endorsement
                    of EE or UW. Visit RootMetrics or{' '}
                    <Link
                      href="https://uw.co.uk/help/contact-us"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ask us for more details
                    </Link>
                    . See{' '}
                    <Link
                      href={GENERAL_TERMS_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms
                    </Link>{' '}
                    for more details.
                  </p>
                </Typography>
              </ContentDropdown>
            </SectionCentered>
            <SectionCentered
              newLayout
              component="section"
              classes={{
                root: classes.sectionPrimary,
                container: classes.section,
              }}
            >
              <div className={classes.featuresWrapper}>
                <article className={classes.feature}>
                  <img src="images/landing/landing_services_01.svg" alt="" />
                  <Typography
                    variant="h4"
                    component="span"
                    className={classes.subSectionTitle}
                  >
                    Choose and customise up to four services to add to your one
                    simple bill
                  </Typography>
                </article>
                <article className={classes.feature}>
                  <img src="images/landing/landing_services_02.svg" alt="" />
                  <Typography
                    variant="h4"
                    component="span"
                    className={classes.subSectionTitle}
                  >
                    We’ll handle the switching process for you
                  </Typography>
                </article>
                <article className={classes.feature}>
                  <img src="images/landing/landing_services_03.svg" alt="" />
                  <Typography
                    variant="h4"
                    component="span"
                    className={classes.subSectionTitle}
                  >
                    You’ll get a personal timeline with all the details of your
                    switch
                  </Typography>
                </article>
              </div>
            </SectionCentered>
            <SectionCentered
              newLayout
              component="section"
              classes={{
                root: classes.sectionWhite,
                container: clsx(classes.section, classes.sectionCashback),
              }}
            >
              <article className={classes.cashbackCopy}>
                <span>
                  <Typography
                    variant="h4"
                    component="h2"
                    color="primary"
                    className={clsx(
                      classes.subSectionTitle,
                      classes.cashbackSectionTitle
                    )}
                  >
                    Money off your monthly UW bill
                  </Typography>
                  <Typography paragraph>
                    For every pound you spend with our cashback card, you can
                    earn up to 10% cashback which comes directly off your
                    monthly UW bill.
                  </Typography>
                  <Typography component="ul" className={classes.cashbackList}>
                    <li className={classes.cashbackListItem}>
                      <FlagPointIcon />
                      <span>
                        Earn <strong>up to 10% cashback</strong> with our retail
                        partners
                      </span>
                    </li>
                    <li className={classes.cashbackListItem}>
                      <FlagPointIcon />
                      <span>
                        Earn 1% everywhere else<sup>*</sup>
                      </span>
                    </li>
                  </Typography>
                </span>

                <ContentDropdown
                  label="The legal bit"
                  open={legalDropdown.cashback}
                  onOpen={() =>
                    setLegalDropdown({
                      ...legalDropdown,
                      cashback: !legalDropdown.cashback,
                    })
                  }
                  classes={{ root: classes.cashbackLegalDropdown }}
                >
                  <Typography component="aside">
                    <p>
                      *You can earn unlimited cashback at the relevant % from
                      our retail partners each month. Purchases from other
                      retailers are eligible for cashback of 1% on spend,
                      subject to cashback caps which vary depending on the
                      number of services you have taken out. You are only
                      eligible to receive the 1% cashback at other retailers if
                      you are not engaged in debt collection proceedings with UW
                      - and have at least one of the following services live at
                      the time of billing: Mobile, Broadband (Broadband and/or
                      Home Phone) or Energy. See our Cashback Card terms and
                      conditions{' '}
                      <Link
                        href={CBC_TERMS_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </Link>
                      .
                    </p>
                  </Typography>
                </ContentDropdown>
              </article>
              <div className={classes.cashbackImages}>
                <picture className={classes.cashbackImage}>
                  <source
                    srcSet="images/landing/cashback.png"
                    media={theme.breakpoints.up('md').replace('@media ', '')}
                  />
                  <source
                    srcSet="images/landing/cashback_tab.png"
                    media={theme.breakpoints.up('sm').replace('@media ', '')}
                  />
                  <img src="images/landing/cashback_mob.png" alt="" />
                </picture>
              </div>
            </SectionCentered>
            <SectionCentered
              component="section"
              newLayout
              classes={{
                container: classes.section,
              }}
            >
              <Typography
                variant="h4"
                component="h2"
                color="primary"
                gutterBottom
              >
                Get a quote
              </Typography>
              <AddressPicker
                isPropertyOwner={isPropertyOwner}
                setIsPropertyOwner={setIsPropertyOwner}
                onConfirm={handleAddressConfirm}
                loading={getQuoteLoading}
                addressPicker={addressPicker}
                getAddressList={getAddressList}
                setManualEntry={setManualEntry}
                setManualEntryField={setManualEntryField}
                addressFields={addressFields}
                onSelectAddress={setAddressSelection}
              />
            </SectionCentered>
          </>
        )}
        <NewCBCChallengeDialog open={showCBCChallengeDialog} />
      </ApplicationCheck>
    </PageTemplate>
  );
};

EntryOrganic.propTypes = {
  getQuote: PropTypes.func.isRequired,
  getQuoteLoading: PropTypes.bool.isRequired,
  setIsPropertyOwner: PropTypes.func.isRequired,
  isPropertyOwner: PropTypes.bool,
  getAddressList: PropTypes.func.isRequired,
  addressPicker: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    addressList: PropTypes.arrayOf(PropTypes.object),
  }),
  setAddressSelection: PropTypes.func.isRequired,
  setManualEntry: PropTypes.func.isRequired,
  setManualEntryField: PropTypes.func.isRequired,
  addressFields: PropTypes.objectOf(PropTypes.string),
  addressExistsDialogOpen: PropTypes.bool.isRequired,
  onAddressExistsDialogContinue: PropTypes.func.isRequired,
};

export default EntryOrganic;
